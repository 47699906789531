/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';

import {
  EuiButtonEmpty,
  EuiFormRow,
  EuiText,
  EuiPanel,
  EuiDatePicker,
  EuiFlexItem,
  EuiFlexGroup,
  EuiFieldText,
  EuiSelect,
  EuiTextArea,
  EuiPage,
  EuiPageBody,
  EuiPageContent,
  EuiPageContentHeader,
  EuiPageContentHeaderSection,
  EuiPageContentBody,
  EuiTitle,
  EuiLoadingSpinner,
  EuiOverlayMask,
  EuiModal,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiModalBody,
  EuiModalFooter,
  EuiButton,
  EuiIcon,
  EuiTab,
  EuiTabs,
  EuiPopover,
  EuiButtonIcon,
  EuiHorizontalRule,
  EuiFilePicker,
} from '@elastic/eui';
import AddPrescriptionsModal from '../components/AddPrescriptionModal';
import ChangeScheduleModal from './consultation/RescheduleModal';
import DiagnosticBadge from './consultation/Laboratory';
import TablePage from '../components/TablePage';
import SessionService from '../services/Sessions';
import ErrorService from '../services/Error.js';
import OrganizationService from '../services/Organizations';
import Payments from '../services/Payments';
import WeblinkService from '../services/Weblink';
import Config from '../Config';
import PrescriptionBadge from './consultation/Prescription';
import SoapBadge from './consultation/Soap';
import SessionBadge from './consultation/Session';
import SettingServices from '../services/Settings';

import moment from 'moment';
import debounce from 'debounce';
import swal from 'sweetalert';
import Blobs from '../services/Blob';
import downloadjs from 'downloadjs';

import '../App.css';
import AddDiagnosticsLabModal from '../components/AddDiagnosticsLabModal';
import CustomBadge from '../components/Badge';
import ChangeActivateEndedSession from './consultation/activateEndedSession';
import CloseSessionModal from './consultation/CloseSessionModal';
import SessionDescriptionModal from './consultation/SessionDescriptionModal';

const blobService = new Blobs();
const errorService = new ErrorService();
const sessionsService = new SessionService();
const settingService = new SettingServices();
const organizationService = new OrganizationService();
const paymentService = new Payments();
const weblinkService = new WeblinkService();

class Consultations extends Component {
  state = {
    page: 1,
    limit: 10,
    order: 'desc',
    search: '',
    searchString: '',
    patientSearchString: '',
    searchBy: 'staffName',
    orderBy: 'date',
    data: [],
    specificDate: moment(),
    dateRange: 'all',
    selectedOrganizationId: '',
    organizationId: '',
    clinicOrganizationId: '',
    sessionId: '',
    showModal: false,
    loading: false,
    availableClinicOrganizations: [],
    selectedTabId: 'today',
    popOver: {},
    popOverSecond: {},
    popOverClaimDoc: {},
    showModalUploadClaim: false,
    fileUploadClaim: null,
    loadingUploadClaim: false,
    loadingUploadClaimOnDB: false,
    showDescription: false,
    prevDescription: '',
    description: '',
    editDescription: false,
    loadingDescription: false,
    sessionItem: {},
    secondary_key: '',
    resultState: 0,
    sessionInsurances: [
      {
        value: '',
        text: 'Semua Sesi',
      },
      {
        value: 'true',
        text: 'Sesi Berasuransi',
      },
    ],
    selectedSessionInsurance: '',
    tabs: [
      {
        id: 'today',
        name: 'Hari ini',
        disabled: false,
      },
      {
        id: 'new',
        name: 'New',
        disabled: false,
      },
      {
        id: 'active',
        name: 'Aktif',
        disabled: false,
      },
      {
        id: 'ended',
        name: 'Ended',
        disabled: false,
      },
      {
        id: 'refunded',
        name: 'Refund',
        disabled: false,
      },
      {
        id: 'disabled',
        name: 'Disabled',
        disabled: false,
      },
      {
        id: 'all',
        name: 'All',
        disabled: false,
      },
    ],
    dateRangeOptions: [
      {
        text: 'Semua',
        value: 'all',
      },
      {
        text: 'Tanggal',
        value: 'specificDate',
      },
    ],
    searchOptions: [
      {
        text: 'Dokter',
        value: 'staffName',
      },
      {
        text: 'Pasien',
        value: 'patientName',
      },
    ],
    column: [
      {
        field: 'date',
        name: 'Waktu',
        sortable: true,
        width: '10%',
        render: (_, item) => {
          let time = moment(item.scheduleTime, 'HH:mm:ss').format('HH:mm');
          return (
            <div
              className='eui-textBreakWord eui-textLeft textBtn'
              onClick={() =>
                this.props.history.push('/consultation/detail/' + item.id)
              }
            >
              {item.scheduleDate}/ {time}/ {item.slotNumber}
            </div>
          );
        },
      },
      {
        field: 'staffName',
        name: 'Staff',
        sortable: true,
        width: '13%',
        render: (staffName, data) => (
          <div
            className='eui-textBreakWord eui-textLeft textBtn'
            onClick={() =>
              this.props.history.push('/staff/detail/' + data.staffId)
            }
          >
            {staffName}
          </div>
        ),
      },

      {
        field: 'patientName',
        name: 'Pasien',
        sortable: true,
        width: '15%',
        render: (patientName, data) => {
          return (
            <div
              id='patient-cell'
              className='eui-textBreakWord eui-textLeft textBtn'
              onClick={() =>
                this.props.history.push('/patient/detail/' + data.patientId)
              }
            >
              {patientName}{' '}
              {data.isVirtualClinic ? (
                <img
                  src={data.virtualClinicConfig.logo}
                  alt={data.virtualClinicConfig.name}
                  style={{ width: '60px', marginLeft: '5px' }}
                />
              ) : null}
            </div>
          );
        },
      },
      {
        field: 'clinicName',
        name: 'Klinik',
        sortable: true,
        width: '13%',
        render: (clinicName, item) => (
          <div
            className='eui-textBreakWord eui-textLeft textBtn'
            onClick={() =>
              this.props.history.push('/consultation/detail/' + item.id)
            }
          >
            {clinicName}
          </div>
        ),
      },
      {
        field: 'id',
        name: 'Status',
        width: '8%',
        render: (sessionId, sessionItem) => (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              width: '-webkit-fill-available',
            }}
            onClick={() =>
              this.props.history.push('/consultation/detail/' + sessionItem.id)
            }
          >
            <SessionBadge sessionId={sessionId} sessionList={this.state.data} />
            <SoapBadge sessionId={sessionId} />
            <PrescriptionBadge sessionId={sessionId} />
            <DiagnosticBadge sessionId={sessionId} />
            {this.isRescheduled(sessionItem.oldSchedule) ? (
              <CustomBadge
                color={'#ffc03f'}
                text={'Rescheduled'}
                iconColor={'#ffc03f'}
              />
            ) : null}
          </div>
        ),
      },
      {
        field: 'id',
        name: 'Aksi',
        width: '8%',
        render: (id, sessionItem) => {
          let logKey = 'logs' + id;
          let statusKey = 'status' + id;
          let labKey = 'lab' + id;
          let paymentKey = 'payment' + id;
          return (
            <div className='eui-textBreakWord textBtn'>
              {/* SiapDok Link */}
              {!moment(sessionItem.scheduleDate).isBefore(
                moment().format('YYYY-MM-DD')
              ) &&
              Config.weblink !== '' &&
              sessionItem.status === 1 ? (
                <EuiButtonEmpty
                  size='xs'
                  className='btnList'
                  onClick={() => window.open('/weblink-list/' + id, '_blank')}
                >
                  SiapDok link
                </EuiButtonEmpty>
              ) : null}
              <EuiPopover
                ownFocus={false}
                button={
                  <EuiButtonEmpty
                    size='xs'
                    className='btnList'
                    onClick={() => this.onPopOverClickSecond(paymentKey)}
                  >
                    Pembayaran
                  </EuiButtonEmpty>
                }
                isOpen={this.state.popOverSecond[paymentKey]?.isOpen}
                closePopover={() => this.onPopOverClickSecond(paymentKey)}
                anchorPosition='upCenter'
                panelPaddingSize='none'
              >
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  {/* Pembayaran */}
                  {sessionItem.status == 0 && (
                    <EuiButtonEmpty
                      size='xs'
                      className='btnList'
                      onClick={() => this.checkPayment(id)}
                    >
                      Cek Pembayaran
                    </EuiButtonEmpty>
                  )}
                  <EuiButtonEmpty
                    size='xs'
                    className='btnList'
                    disabled={!sessionItem.paymentURL}
                    onClick={() =>
                      window.open(sessionItem.paymentURL, '_blank')
                    }
                  >
                    Link Pembayaran Sesi Telekonsul
                  </EuiButtonEmpty>
                  <EuiButtonEmpty
                    size='xs'
                    className='btnList'
                    disabled={sessionItem.status < 1}
                    onClick={() =>
                      this.openReceiptBlob(id, sessionItem.current_language)
                    }
                  >
                    Unduh Kwitansi
                  </EuiButtonEmpty>
                  {sessionItem.orderId && sessionItem.orderId.length > 0 && (
                    <EuiButtonEmpty
                      size='xs'
                      className='btnList'
                      disabled={sessionItem.status === 1}
                      onClick={() => this.completePayment(sessionItem.orderId)}
                    >
                      Tandai Sesi Telah Dibayar
                    </EuiButtonEmpty>
                  )}
                </div>
              </EuiPopover>
              <EuiPopover
                ownFocus={false}
                button={
                  <EuiButtonEmpty
                    size='xs'
                    className='btnList'
                    onClick={() => this.onPopOverClickSecond(labKey)}
                  >
                    Resep dan Lab
                  </EuiButtonEmpty>
                }
                isOpen={this.state.popOverSecond[labKey]?.isOpen}
                closePopover={() => this.onPopOverClickSecond(labKey)}
                anchorPosition='upCenter'
                panelPaddingSize='none'
              >
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  {/* Resep dan Lab */}
                  <EuiButtonEmpty
                    size='xs'
                    className='btnList'
                    onClick={() =>
                      this.props.history.push({
                        pathname: '/pharmacies/kimia-farma',
                        state: { searchBy: 'session_id', searchString: id },
                      })
                    }
                  >
                    Lihat Resep
                  </EuiButtonEmpty>
                  <AddPrescriptionsModal item={sessionItem} />
                  <EuiButtonEmpty
                    size='xs'
                    className='btnList'
                    onClick={() =>
                      this.props.history.push(
                        '/laboratory-test/' + sessionItem.id
                      )
                    }
                  >
                    Lihat Lab
                  </EuiButtonEmpty>
                  <AddDiagnosticsLabModal item={sessionItem} />
                </div>
              </EuiPopover>
              <EuiPopover
                ownFocus={false}
                button={
                  <EuiButtonEmpty
                    size='xs'
                    className='btnList'
                    onClick={() => this.onPopOverClickSecond(statusKey)}
                  >
                    Status sesi
                  </EuiButtonEmpty>
                }
                isOpen={this.state.popOverSecond[statusKey]?.isOpen}
                closePopover={() => this.onPopOverClickSecond(statusKey)}
                anchorPosition='upCenter'
                panelPaddingSize='none'
              >
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  {/* Status sesi */}
                  <ChangeScheduleModal
                    sessionItem={sessionItem}
                    onScheduleChange={() => this.page(this.state.page)}
                  />
                  {sessionItem.status > 1 && (
                    <ChangeActivateEndedSession
                      sessionItem={sessionItem}
                      onActivate={() => this.page(1)}
                      closePopover={() => this.onPopOverClickSecond(id)}
                    />
                  )}
                  {sessionItem.status === 1 && (
                    <CloseSessionModal
                      sessionItem={sessionItem}
                      onScheduleChange={() => this.page(1)}
                    />
                  )}
                  {sessionItem.status === 1 && (
                    <SessionDescriptionModal
                      sessionItem={sessionItem}
                      onScheduleChange={() => this.page(1)}
                    />
                  )}
                  {sessionItem.status > 1 && (
                    <EuiButtonEmpty
                      size='xs'
                      className='btnList'
                      onClick={() => this.resetStatus(sessionItem)}
                    >
                      Reset Status
                    </EuiButtonEmpty>
                  )}
                  {sessionItem.status == 1 && (
                    <EuiButtonEmpty
                      size='xs'
                      className='btnList'
                      onClick={() => this.rerunEventPaymentReceived(id)}
                    >
                      Tambahkan ulang SESSION_PAYMENT_RECEIVED
                    </EuiButtonEmpty>
                  )}
                </div>
              </EuiPopover>
              <EuiPopover
                ownFocus={false}
                button={
                  <EuiButtonEmpty
                    size='xs'
                    className='btnList'
                    onClick={() => this.onPopOverClickSecond(logKey)}
                  >
                    {'Logs'}
                  </EuiButtonEmpty>
                }
                isOpen={this.state.popOverSecond[logKey]?.isOpen}
                closePopover={() => this.onPopOverClickSecond(logKey)}
                anchorPosition='upCenter'
                panelPaddingSize='none'
              >
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <EuiButtonEmpty
                    size='xs'
                    className='btnList'
                    onClick={() =>
                      this.props.history.push({
                        pathname: '/events',
                        state: { secondary_key: id },
                      })
                    }
                  >
                    Log Events
                  </EuiButtonEmpty>
                  <EuiButtonEmpty
                    size='xs'
                    className='btnList'
                    onClick={() => {
                      var endDate;
                      var startDate = sessionItem.createdTime;
                      if (sessionItem.oldSchedule) {
                        let oldSchedule = moment(
                          sessionItem.oldSchedule.split('T')[0]
                        );
                        let scheduleDate = moment(sessionItem.scheduleDate);
                        if (oldSchedule.isBefore(scheduleDate)) {
                          endDate = scheduleDate.format('YYYY-MM-DD');
                        } else {
                          endDate = oldSchedule.format('YYYY-MM-DD');
                        }
                      } else {
                        endDate = sessionItem.scheduleDate;
                      }

                      this.props.history.push({
                        pathname: '/thirdparty-logs',
                        state: {
                          key: 'payload',
                          keyword: id,
                          startDate: startDate,
                          endDate: endDate,
                        },
                      });
                    }}
                  >
                    Log Bridging
                  </EuiButtonEmpty>
                </div>
              </EuiPopover>
            </div>
          );
        },
        sortable: false,
      },
    ],
    loadingData: false,
    reports: [],
    reportSummaries: {
      finishedViaSiapdok: 0,
      finishedViaAlternatives: 0,
      finishedPercentage: 0,
    },
    showReports: false,
    loadingReport: false,
    downloadingPrescriptionCopy: {},
    downloadingPrescriptionCopyEn: {},
  };

  insuranceText = (session) => {
    let s = session.insuranceVendor || '-';
    if (s != '-' && session.insuranceStatus) {
      switch (session.insuranceStatus) {
        case 1:
          s += ' (NOT ELIGIBLE)';
          break;
        case 2:
          s += ' (EXCESS)';
          break;
        case 3:
          s += ' (ELIGIBLE)';
          break;
        case 4:
          s += ' (ADMISSIONED)';
          break;
        case 5:
          s += ' (DISCHARGED)';
          break;
        case 6:
          s += ' (CLAIM SENT)';
          break;
        default:
          s += ' (UNKNOWN)';
          break;
      }
    }
    return s;
  };

  resetStatus = (data) => {
    let payload = {};
    if (
      data.paymentURL === '' ||
      data.paymentURL === undefined ||
      data.paymentURL === 'free'
    ) {
      payload.Status = 0;
    } else {
      payload.Status = 1;
    }
    payload.Description = `reset manual`;
    payload.Id = data.id;
    if (!window.confirm('Apakah Anda yakin ingin mereset status item ini?')) {
      return;
    }

    sessionsService
      .updateSessionStatus(payload)
      .then((result) => {
        this.page(this.state.page);
        this.onPopOverClick(data.id);
      })
      .catch((err) => {
        console.log(err);
        swal({
          title: '',
          icon: 'error',
          type: 'error',
          text: `Mohon maaf, status tidak bisa direset`,
        });
      });
  };

  showDescription = (description, sessionItem) => {
    this.setState({
      showDescription: true,
      prevDescription: description,
      description,
      sessionItem,
    });
  };

  onPopOverClick = (id) => {
    let state = this.state;
    if (!state.popOver[id]) {
      state.popOver[id] = { isOpen: true };
    } else {
      state.popOver[id] = { isOpen: !state.popOver[id].isOpen };
    }
    this.setState(state);
  };

  onPopOverClickSecond = (id) => {
    let state = this.state;
    if (!state.popOverSecond[id]) {
      state.popOverSecond[id] = { isOpen: true };
    } else {
      state.popOverSecond[id] = { isOpen: !state.popOverSecond[id].isOpen };
    }
    this.setState(state);
  };

  closePopOver = (id) => {
    let state = this.state;
    state.popOver[id] = { isOpen: false };
    this.setState(state);
  };

  componentDidMount = () => {
    const queryParams = new URLSearchParams(window.location.search);
    const status = queryParams.get('status');
    console.log('queryParams: ' + queryParams);
    if (status) {
      this.state.selectedTabId = status;
    }
    this.page(1);
    this.loadOrganizations();
  };

  statusRender = (status) => {
    switch (status) {
      case 0:
        return 'NEW';
      case 1:
        return 'ACTIVE';
      case 2:
        return 'REJECTED';
      case 3:
        return 'DISABLED';
      case 4:
        return 'ENDED';
      case 5:
        return 'CANCELED';
      case 7:
        return 'REFUND';
      default:
        return 'UNKNOWN';
    }
  };

  statusColor = (status) => {
    switch (status) {
      case 0:
      case false:
        return '#ffc03f';
      case 1:
      case true:
        return '#3f9f30';
      case 2:
        return '#d3dbe6';
      default:
        return '#f6413a';
    }
  };

  loadOrganizations = () => {
    let payload = {
      limit: 1000,
    };
    organizationService
      .getOrganizations(payload)
      .then((result) => {
        let options = [{ value: '', text: 'Pilih organisasi' }];
        for (let i in result.items) {
          options.push({
            value: result.items[i].id,
            text: result.items[i].name,
          });
        }
        this.setState({
          organizations: options,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          organizations: [],
        });
      });
  };

  closeModal = () => {
    this.setState({
      showModal: false,
      logsData: [],
    });
  };

  downloadClaimDoc = (id) => {
    this.setState({ downloadingClaimDoc: true }, () => {
      blobService
        .getBlob(id)
        .then((result) => {
          downloadjs(
            `data:${result.contentType};base64,${result.base64}`,
            `document_claim_${id}.pdf`,
            `${result.contentType}`
          );
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => this.setState({ downloadingClaimDoc: false }));
    });
  };

  downloadPrescriptionCopy = (id, lang) => {
    // set loading to specific id
    let key = 'downloadingPrescriptionCopy';
    if (lang === 'en') {
      key = 'downloadingPrescriptionCopyEn';
    }

    this.setState(
      (prevState) => ({
        [key]: {
          ...prevState[key],
          [id]: true,
        },
      }),
      () => {
        sessionsService
          .getPrescriptionCopy(id, lang)
          .then((result) => {
            let fileName = `prescription_${id}.pdf`;
            if (lang === 'en') {
              fileName = `prescription_${id}_en.pdf`;
            }
            downloadjs(result, fileName, `application/pdf`);
          })
          .catch((err) => {
            console.log(err);
            if (err.response?.status === 404) {
              swal({
                title: '',
                icon: 'error',
                type: 'error',
                text: `Mohon maaf, sesi ini belum atau tidak memiliki resep.`,
              });
            } else {
              errorService.handle(err);
            }
          })
          .finally(() => {
            this.onPopOverClick(id);
            this.setState((prevState) => ({
              [key]: {
                ...prevState[key],
                [id]: false,
              },
            }));
          });
      }
    );
  };

  isSoapComplete = (sessionId) => {
    if (this.state.soaps) {
      return this.state.soaps.includes(sessionId);
    } else {
      return false;
    }
  };

  isPrescription = (sessionId) => {
    if (this.state.prescriptions.includes(`${sessionId}`)) {
      return true;
    }
    return false;
  };

  isRescheduled = (oldSchedule) => {
    let time = moment(oldSchedule);
    return time.isValid() && time.year() !== 1;
  };

  loadReport = () => {
    let payload = {
      page: 1,
      limit: 10000000,
      order: this.state.order,
      orderBy: this.state.orderBy,
    };

    if (this.state.selectedTabId === 'today') {
      payload.date = moment().format('YYYY-MM-DD');
    } else if (this.state.selectedTabId !== 'all') {
      payload.filterBy = this.state.selectedTabId;
    }

    if (
      this.state.dateRange === 'specificDate' &&
      this.state.specificDate &&
      typeof this.state.specificDate === 'object'
    ) {
      payload.date = this.state.specificDate.format('YYYY-MM-DD');
    }
    if (
      this.state.searchBy === 'patientName' &&
      this.state.patientSearchString &&
      this.state.patientSearchString.length > 0
    ) {
      payload.search = this.state.patientSearchString;
      payload.searchBy = this.state.searchBy;
    }
    if (
      this.state.searchBy === 'staffName' &&
      this.state.searchString &&
      this.state.searchString.length > 0
    ) {
      payload.search = this.state.searchString;
      payload.searchBy = this.state.searchBy;
    }
    if (this.state.organizationId && this.state.organizationId.length > 0) {
      payload.organizationId = this.state.organizationId;
    }

    this.setState(
      { showReports: true, loadingReport: true, reports: [] },
      () => {
        sessionsService
          .getSessions(payload)
          .then((result) => {
            let summaries = {
              finishedViaSiapdok: 0,
              finishedViaAlternatives: 0,
              finishedPercentage: 0,
              refund: 0,
            };

            // sort by schedule > scheduleTime > slotNumber
            result.Items = this.sortItemsBySchedule(result.Items, {
              schedule: 'asc',
              scheduleTime: 'asc',
              slotId: 'asc',
              slotNumber: 'asc',
            });

            for (let i in result.Items) {
              let v = result.Items[i];
              if (v.finishedPlatform > 0) {
                summaries.finishedViaAlternatives += 1;
              } else {
                summaries.finishedViaSiapdok += 1;
              }
              if (v.status == 7) {
                summaries.refund += 1;
              }
            }
            summaries.finishedPercentage =
              (summaries.finishedViaSiapdok / result.Items.length) * 100;
            let obj = {
              reports: result.Items,
              reportSummaries: summaries,
              loadingReport: false,
            };
            this.setState(obj);
          })
          .catch((err) => {
            console.log(err);
            let obj = {
              loadingReport: false,
            };
            this.setState(obj);
          });
      }
    );
  };

  page = (page) => {
    page = page || 1;
    let payload = {
      page: page,
      limit: this.state.limit,
      search: this.state.search,
      order: this.state.order,
      orderBy: this.state.orderBy,
      hasInsuranceVendor: this.state.selectedSessionInsurance,
    };

    if (this.state.selectedTabId === 'today') {
      payload.date = moment().format('YYYY-MM-DD');
    } else if (this.state.selectedTabId !== 'all') {
      payload.filterBy = this.state.selectedTabId;
    }

    if (
      this.state.dateRange === 'specificDate' &&
      this.state.specificDate &&
      typeof this.state.specificDate === 'object'
    ) {
      payload.date = this.state.specificDate.format('YYYY-MM-DD');
    }
    if (
      this.state.searchBy === 'patientName' &&
      this.state.patientSearchString &&
      this.state.patientSearchString.length > 0
    ) {
      payload.search = this.state.patientSearchString;
      payload.searchBy = this.state.searchBy;
    }
    if (
      this.state.searchBy === 'staffName' &&
      this.state.searchString &&
      this.state.searchString.length > 0
    ) {
      payload.search = this.state.searchString;
      payload.searchBy = this.state.searchBy;
    }
    if (this.state.organizationId && this.state.organizationId.length > 0) {
      payload.organizationId = this.state.organizationId;
    }

    let virtualClinicJson;
    settingService.getAppSettings('virtual-clinic-config').then((res) => {
      virtualClinicJson = JSON.parse(res.data);
    });

    this.setState({ loadingData: true }, () => {
      sessionsService
        .getSessions(payload)
        .then((result) => {
          var startFrom = (parseInt(result.Page) - 1) * parseInt(result.Limit);

          // sort by schedule > scheduleTime > slotNumber
          result.Items = this.sortItemsBySchedule(result.Items, {
            schedule: 'asc',
            scheduleTime: 'asc',
            slotId: 'asc',
            slotNumber: 'asc',
          });
          console.log(result.Items);
          for (var a in result.Items) {
            var item = result.Items[a];
            item.sequence = parseInt(a) + startFrom + 1;
            item.weblinks = {
              doctor: '',
              patient: '',
              assistant: '',
            };
            item.resultState = 0;
            if (item.tags !== null) {
              for (var b = 0; b < item.tags.length; b++) {
                if (item.tags[b].startsWith('products:virtual-clinic')) {
                  let tagParts = item.tags[b].split('-');
                  item.isVirtualClinic = true;
                  item.virtualClinicConfig =
                    virtualClinicJson[tagParts[tagParts.length - 1]];
                  break;
                }
              }
            }
          }
          let obj = {
            page: result.Page,
            limit: result.Limit,
            total: result.Total,
            data: result.Items,
            loadingData: false,
          };

          obj[this.state.currentSlotId] = false;
          console.log(result.Items);
          this.setState(obj);
          this.forceUpdate();
        })
        .catch((err) => {
          console.log(err);
          let obj = {
            loadingData: false,
            data: [],
            loading: false,
          };
          obj[this.state.currentSlotId] = false;
          this.setState(obj);
        });
    });
  };

  statusToTab = (status) => {
    switch (status) {
      case 0:
        return 'new';
      case 1:
        return 'active';
      case 3:
        return 'disabled';
      case 4:
        return 'ended';
      case 7:
        return 'refund';
      default:
        return 'all';
    }
  };

  updateSessionPlatformFinished = async (id, platform, session) => {
    let payload = {
      finished_platform: platform,
    };

    try {
      let resp = await sessionsService.updateSessionFinishedPlatform(
        id,
        payload
      );
      switch (platform) {
        case 0:
          platform = 'SiapDok';
          break;
        case 1:
          platform = 'ZOOM';
          break;
        case 2:
          platform = 'WhatsApp';
          break;
        default:
          break;
      }
      if (resp != null) {
        swal({
          title: `Berhasil Tuntas Via ${platform}`,
          icon: 'success',
          type: 'success',
        }).then(() => {
          this.onSelectedTabChanged(
            this.statusToTab(session.status),
            session.staffName
          );
        });
      }
    } catch (err) {
      errorService.handle(err);
    }
  };

  webLinkBySessionID = async (sessionId, index) => {
    const payload = { Id: sessionId };
    try {
      let _weblink = await weblinkService.listWeblink(payload);
      const weblinkLen = Object.keys(_weblink).length;
      if (weblinkLen == 0) {
        Object.assign(payload, { session_id: sessionId, role: 'ALL' });
        let _createWeblink = await weblinkService.createWeblink(payload);
        if (_createWeblink.message.includes(',')) {
          _weblink = await weblinkService.listWeblink(payload);
        }
      }

      _weblink.items.forEach((element) => {
        if (element.role == 'DOCTOR') {
          this.state.data[index].weblinks.doctor = element.id;
        }
        if (element.role == 'PATIENT') {
          this.state.data[index].weblinks.patient = element.id;
        }
        if (element.role == 'ASSISTANT') {
          this.state.data[index].weblinks.assistant = element.id;
        }
      });
    } catch (e) {
      console.log(e);
    }
  };

  nextPage = () => {
    this.page(this.state.page + 1);
  };

  prevPage = () => {
    this.page(this.state.page - 1);
  };

  handleDateChange = (date) => {
    // in moment object format
    this.setState({ specificDate: date }, () => {
      this.page(1);
    });
  };

  handleChange = (e) => {
    let value = e.target.value || null;
    let obj = {};
    obj[e.target.name] = value;
    obj['failedAttempt'] = false;
    let validationFields = { ...this.state.validationFields };
    if (validationFields[e.target.name]) {
      validationFields[e.target.name].isInvalid = false;
      obj.validationFields = validationFields;
    }
    this.setState(obj);
  };

  onOrganizationChange = (e) => {
    let value = e.target.value || null;
    this.setState({ loadingData: true, organizationId: value }, () => {
      this.page(1);
    });
  };

  onSessionInsuranceChange = (e) => {
    let value = e.target.value || null;
    this.setState(
      { loadingData: true, selectedSessionInsurance: value },
      () => {
        this.page(1);
      }
    );
  };

  onDateRangeChange = (e) => {
    let value = e.target.value || null;
    this.setState({ dateRange: value, specificDate: moment() }, () => {
      this.page(1);
    });
  };

  onSearchByChange = (e) => {
    let value = e.target.value || null;
    this.setState({ searchBy: value });
  };

  searchStringChange = debounce((value) => {
    this.setState({ searchString: value }, () => {
      this.page(1);
    });
  }, 1000);

  onSearchStringChange = (e) => {
    let value = e.target.value || null;
    if (!value || (value && value.length < 1)) {
      this.setState({ searchString: '' });
    }
    this.setState({ searchString: value });
    this.searchStringChange(value);
  };

  patientSearchStringChange = debounce((value) => {
    this.setState({ patientSearchString: value }, () => {
      this.page(1);
    });
  }, 1000);

  onPatientSearchStringChange = (e) => {
    let value = e.target.value || null;
    if (!value || (value && value.length < 1)) {
      this.setState({ patientSearchString: '' });
    }
    this.setState({ patientSearchString: value });
    this.patientSearchStringChange(value);
  };

  checkPayment = (id) => {
    if (id && id.length !== 36) {
      swal({
        title: '',
        icon: 'error',
        type: 'error',
        text: 'Mohon maaf, id invalid',
      });
    }
    paymentService
      .checkPayment(id)
      .then((result) => {
        let text = '';
        if (result.status === 'paid') {
          text = 'Pembayaran telah lunas';
        } else {
          text = 'Pembayaran belum lunas';
        }
        swal({
          title: 'Cek pembayaran telah berhasil',
          icon: 'success',
          type: 'success',
          text: text,
        }).then((value) => {
          this.page(this.state.page);
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  rerunEventPaymentReceived = async (id) => {
    if (id && id.length !== 36) {
      swal({
        title: '',
        icon: 'error',
        type: 'error',
        text: 'Mohon maaf, id invalid',
      });
    }
    try {
      let resultEvent = await sessionsService.rerunPaymentReceivedEvents({
        id: id,
      });

      if (resultEvent.message == 'success') {
        swal({
          title: '',
          icon: 'success',
          type: 'success',
          text: 'Event berhasil dieksekusi',
        });
      } else {
        swal({
          title: '',
          icon: 'warning',
          type: 'warning',
          text: 'Event sudah tersedia sebelumnya',
        });
      }
    } catch (e) {
      console.log(e);
      swal({
        title: '',
        icon: 'error',
        type: 'error',
        text: `Mohon maaf, terjadi kesalahan dengan galat \n\n ${e}`,
      });
    }
  };

  completePayment = (id) => {
    if (id && id.length !== 36) {
      swal({
        title: '',
        icon: 'error',
        type: 'error',
        text: 'Mohon maaf, id order invalid',
      });
    }
    paymentService
      .completePaymentByOrderId(id)
      .then((result) => {
        swal({
          title: 'Sukses',
          icon: 'success',
          type: 'success',
          text: 'Sesi telah dibayar',
        }).then((value) => {
          this.page(this.state.page);
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  openReceiptBlob = (id, lang) => {
    this.setState({ downloadLoading: true });
    var langCode = '';
    if (lang === 1) {
      langCode = 'en';
    } else {
      langCode = 'id';
    }
    sessionsService
      .getSessionReceipt(id, langCode)
      .then((value) => {
        var binaryData = [];
        binaryData.push(value);
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(
          new Blob(binaryData, { type: 'application/pdf' })
        );
        link.download = `payment-receipt-${id}`;
        link.click();

        this.setState({
          downloadLoading: false,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          downloadLoading: false,
        });
      });
  };

  renderFilterComponent = () => {
    return (
      <div>
        <div
          style={{ display: 'inline-block', marginLeft: 15, marginRight: 15 }}
        >
          <EuiFlexGroup>
            <EuiFlexItem grow={false}>
              <EuiSelect
                placeholder='Organisasi'
                options={this.state.organizations}
                value={this.state.organizationId}
                name='organizationName'
                onChange={this.onOrganizationChange}
                aria-label='Organisasi'
              />
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiSelect
                options={this.state.sessionInsurances}
                value={this.state.selectedSessionInsurance}
                name='sessionInsurance'
                onChange={this.onSessionInsuranceChange}
                aria-label='Asuransi'
              />
            </EuiFlexItem>
          </EuiFlexGroup>
        </div>
        {this.state.selectedTabId !== 'today' && (
          <div style={{ display: 'inline-block' }}>
            <EuiFlexGroup>
              <EuiFlexItem grow={false} style={{ marginRight: '-10px' }}>
                <EuiSelect
                  style={{ display: 'inline-block !important' }}
                  placeholder='Cari berdasarkan'
                  options={this.state.dateRangeOptions}
                  value={this.state.dateRange}
                  name='dateRange'
                  onChange={this.onDateRangeChange}
                  aria-label='Cari berdasarkan'
                />
              </EuiFlexItem>
              {this.state.dateRange === 'specificDate' && (
                <EuiFlexItem grow={false}>
                  <EuiDatePicker
                    selected={this.state.specificDate}
                    onChange={this.handleDateChange}
                  />
                </EuiFlexItem>
              )}
            </EuiFlexGroup>
          </div>
        )}
        <div style={{ display: 'inline-block', marginLeft: 30 }}>
          <EuiFlexGroup>
            <EuiFlexItem grow={false} style={{ marginRight: '-10px' }}>
              <EuiSelect
                style={{ display: 'inline-block !important' }}
                placeholder='Cari berdasarkan'
                options={this.state.searchOptions}
                value={this.state.searchBy}
                name='searchBy'
                onChange={this.onSearchByChange}
                aria-label='Cari berdasarkan'
              />
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              {this.state.searchBy === 'staffName' && (
                <EuiFieldText
                  style={{ display: 'inline-block !important' }}
                  placeholder='Cari dokter...'
                  name='searchString'
                  onChange={this.onSearchStringChange}
                  value={this.state.searchString}
                />
              )}
              {this.state.searchBy === 'patientName' && (
                <EuiFieldText
                  style={{ display: 'inline-block !important' }}
                  placeholder='Cari...'
                  name='searchString'
                  onChange={this.onPatientSearchStringChange}
                  value={this.state.patientSearchString}
                />
              )}
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiButton
                color='primary'
                onClick={() => {
                  this.loadReport();
                }}
                iconType='lensApp'
              >
                Laporan Teks
              </EuiButton>
            </EuiFlexItem>
          </EuiFlexGroup>
        </div>
      </div>
    );
  };

  onSelectedTabChanged = (id, searchString = '') => {
    this.setState(
      {
        loadingData: true,
        selectedTabId: id,
        searchDate: '',
        searchString: searchString,
        specificDate: moment(),
      },
      () => {
        this.page(1);
        this.loadOrganizations();
      }
    );
  };

  onTableChange = ({ page = {}, sort = {} }) => {
    const { field: sortField, direction: sortDirection } = sort;
    console.log(sortField, sortDirection);
    this.setState({ order: sortDirection, orderBy: sortField }, () => {
      this.page(this.state.page);
    });
  };

  sortItemsBySchedule(items, sortOrder) {
    return items.sort((a, b) => {
      const compareStrings = (strA, strB) => {
        return strA.localeCompare(strB);
      };

      const compareValues = (valueA, valueB, sortOrder) => {
        if (sortOrder === 'asc') {
          return valueA < valueB ? -1 : valueA > valueB ? 1 : 0;
        } else {
          return valueB < valueA ? -1 : valueB > valueA ? 1 : 0;
        }
      };

      // sort by schedule
      const scheduleComparison = compareStrings(a.scheduleDate, b.scheduleDate);
      if (scheduleComparison !== 0)
        return compareValues(scheduleComparison, 0, sortOrder.schedule);

      // sort by scheduleTime
      const scheduleTimeComparison = compareStrings(
        a.scheduleTime,
        b.scheduleTime
      );
      if (scheduleTimeComparison !== 0)
        return compareValues(scheduleTimeComparison, 0, sortOrder.scheduleTime);

      // sort by slotId
      const slotIdComparison = compareStrings(a.slotId, b.slotId);
      if (slotIdComparison !== 0)
        return compareValues(slotIdComparison, 0, sortOrder.slotId);

      // sort by slotNumber
      return compareValues(a.slotNumber, b.slotNumber, sortOrder.slotNumber);
    });
  }

  renderTabs = () => {
    return this.state.tabs.map((tab, index) => (
      <EuiTab
        {...(tab.href && { href: tab.href, target: '_blank' })}
        onClick={() => this.onSelectedTabChanged(tab.id)}
        isSelected={tab.id === this.state.selectedTabId}
        disabled={tab.disabled}
        key={index}
      >
        {tab.name}
      </EuiTab>
    ));
  };

  render() {
    const sorting = {
      sort: {
        field: this.state.orderBy,
        direction: this.state.order,
      },
    };

    let modalDescription;
    let modalReports;
    let modalUploadClaim;

    if (this.state.showDescription) {
      modalDescription = (
        <EuiOverlayMask>
          <EuiModal
            onClose={() => this.setState({ showDescription: false })}
            initialFocus='[name=description]'
          >
            <EuiModalHeader>
              <EuiModalHeaderTitle>
                Deskripsi Temu-janji Konsultasi
              </EuiModalHeaderTitle>
            </EuiModalHeader>

            <EuiModalBody>
              <EuiPanel>
                <EuiText>
                  <p>
                    <b>{this.state.sessionItem?.patientName}</b>
                    <br />
                    <small>
                      {this.state.sessionItem?.patientExternalId || '-'}
                    </small>
                    <br />
                    <br />
                    <small>
                      <b>{this.state.sessionItem?.staffName}</b>
                    </small>
                    <br />
                    <small>{this.state.sessionItem?.clinicName}</small>
                    <br />
                    <small>
                      {`${moment(this.state.sessionItem?.scheduleDate).format(
                        'dddd'
                      )}`}
                      ,{' '}
                      {`${moment(this.state.sessionItem?.scheduleDate).format(
                        'LL'
                      )}`}{' '}
                      pukul {this.state.sessionItem?.scheduleTime} <br />
                    </small>
                    <br />
                    <small>
                      Rescheduled:{' '}
                      <b>
                        {this.isRescheduled(this.state.sessionItem?.oldSchedule)
                          ? 'Ya'
                          : 'Tidak'}
                      </b>
                    </small>
                    <br />
                    {this.isRescheduled(
                      this.state.sessionItem?.oldSchedule
                    ) && (
                      <small>
                        Jadwal lama:{' '}
                        {moment(this.state.sessionItem?.oldSchedule).format(
                          'dddd, LL [pukul] HH:mm:ss'
                        )}
                      </small>
                    )}
                  </p>
                </EuiText>
              </EuiPanel>
              <EuiFormRow label='Deskripsi'>
                <div style={{ position: 'relative' }}>
                  {!this.state.editDescription && (
                    <EuiButtonIcon
                      style={{ position: 'absolute', top: 0, right: 0 }}
                      onClick={() => this.setState({ editDescription: true })}
                      iconType='indexEdit'
                      aria-label='option'
                      color='secondary'
                      size='m'
                    />
                  )}
                  <EuiTextArea
                    placeholder={
                      this.state.editDescription ? 'Masukan deskripsi...' : ''
                    }
                    aria-label='description'
                    value={this.state.description}
                    resize={this.state.editDescription ? 'both' : 'none'}
                    onChange={(e) =>
                      this.setState({ description: e.target.value })
                    }
                    readOnly={!this.state.editDescription}
                  />
                </div>
              </EuiFormRow>
            </EuiModalBody>
            {this.state.editDescription && (
              <EuiModalFooter>
                <EuiButton
                  isLoading={this.state.loadingDescription}
                  onClick={() => {
                    let payload = {};
                    payload.Status = this.state.sessionItem.status;
                    payload.Description = this.state.description;
                    payload.Id = this.state.sessionItem.id;

                    this.setState({ loadingDescription: true }, () => {
                      sessionsService
                        .updateSessionDescription(payload)
                        .then(() => {
                          swal({
                            title:
                              'Berhasil Sunting Deskripsi Temu-Janji Konsultasi',
                            icon: 'success',
                            type: 'success',
                          }).then((value) => {
                            this.setState(
                              {
                                loadingDescription: false,
                                editDescription: false,
                                showDescription: false,
                                loadingData: true,
                              },
                              () => this.page(this.state.page)
                            );
                          });
                        })
                        .catch((err) => {
                          console.log(err);
                          this.setState({ loadingDescription: false });
                        });
                    });
                  }}
                  fill
                  disabled={!this.state.description}
                >
                  Submit
                </EuiButton>
                <EuiButtonEmpty
                  onClick={() =>
                    this.setState({
                      editDescription: false,
                      description: this.state.prevDescription,
                    })
                  }
                >
                  Batal
                </EuiButtonEmpty>
              </EuiModalFooter>
            )}
          </EuiModal>
        </EuiOverlayMask>
      );
    }
    if (this.state.showReports) {
      modalReports = (
        <EuiOverlayMask>
          <EuiModal
            onClose={() => this.setState({ showReports: false })}
            initialFocus='[name=reports]'
          >
            <EuiModalHeader>
              <EuiModalHeaderTitle>
                Laporan Temu-janji Konsultasi
              </EuiModalHeaderTitle>
              <div style={{ float: 'right' }}>
                <br />
                Jumlah konsultasi: {this.state.reports.length}
                <br />
                Refund: {this.state.reportSummaries.refund}
                <br />
                Jumlah konsultasi tuntas:{' '}
                {this.state.reports.length - this.state.reportSummaries.refund}
                <br />
                Tuntas via SiapDok:{' '}
                {this.state.reportSummaries.finishedViaSiapdok}
                <br />
                Tuntas via alternatif:{' '}
                {this.state.reportSummaries.finishedViaAlternatives}
                <br />
                Skor: {this.state.reportSummaries.finishedPercentage}%
              </div>
            </EuiModalHeader>

            <EuiModalBody>
              {this.state.loadingReport ? (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <EuiLoadingSpinner size='xl' />
                </div>
              ) : (
                <EuiPanel>
                  {(this.state.reports || []).length < 1 ? (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      Tidak ada data
                    </div>
                  ) : (
                    <div>
                      {this.state.reports.map((v, i) => {
                        let scheduleDate = moment(v.scheduleDate, 'YYYY-MM-DD');

                        return (
                          <div>
                            <EuiText key={i}>
                              {i + 1}. {scheduleDate.format('dddd')}{' '}
                              {v.scheduleDate} {v.scheduleTime} {v.clinicName} -{' '}
                              {v.staffName} / pasien #{v.slotNumber}{' '}
                              {v.patientName} / {v.patientExternalId || '-'} /{' '}
                              {v.patientPhoneNumber || '-'} /{' '}
                              {v.description || '-'} / {v.preferredLab} /{' '}
                              {v.preferredPharmacy} / Tuntas via{' '}
                              {v.finishedPlatform === 0
                                ? 'SiapDok'
                                : v.finishedPlatform === 1
                                ? 'Zoom'
                                : 'WhatsApp'} {v.description ? `/ ${v.description}`:''}
                            </EuiText>
                            {i !== this.state.reports.length - 1 && (
                              <EuiHorizontalRule />
                            )}
                          </div>
                        );
                      })}
                    </div>
                  )}
                </EuiPanel>
              )}
            </EuiModalBody>
            <EuiModalFooter>
              {(this.state.reports || []).length > 0 && (
                <EuiButton
                  fill
                  onClick={() => {
                    var data = '';
                    this.state.reports.map((v, i) => {
                      let scheduleDate = moment(v.scheduleDate, 'YYYY-MM-DD');
                      data += `\n${i + 1}. ${scheduleDate.format('dddd')} ${
                        v.scheduleDate
                      } ${v.scheduleTime} ${v.clinicName} - ${
                        v.staffName
                      } / pasien #${v.slotNumber} ${v.patientName} / ${
                        v.patientExternalId || '-'
                      } / ${v.patientPhoneNumber || '-'} / ${
                        v.description || '-'
                      } / ${v.preferredLab || '-'} / ${
                        v.preferredPharmacy || '-'
                      } / Tuntas via ${
                        v.finishedPlatform === 0
                          ? 'SiapDok'
                          : v.finishedPlatform === 1
                          ? 'Zoom'
                          : 'WhatsApp'
                      }`;
                    });

                    data += `\n\nJumlah konsultasi: ${this.state.reports.length}`;
                    data += `\n\nRefund: ${this.state.reportSummaries.refund}`;
                    data += `\n\nJumlah konsultasi tuntas: ${
                      this.state.reports.length -
                      this.state.reportSummaries.refund
                    }`;
                    data += `\nTuntas via SiapDok: ${this.state.reportSummaries.finishedViaSiapdok}`;
                    data += `\nTuntas via alternatif: ${this.state.reportSummaries.finishedViaAlternatives}`;
                    data += `\nSkor: ${this.state.reportSummaries.finishedPercentage}%`;

                    var link = document.createElement('a');
                    var mimeType = 'text/plain';

                    link.setAttribute('download', 'laporan_temu_janji.txt');
                    link.setAttribute(
                      'href',
                      'data:' +
                        mimeType +
                        ';charset=utf-8,' +
                        encodeURIComponent(data)
                    );
                    link.click();
                  }}
                >
                  Download txt
                </EuiButton>
              )}
              <EuiButtonEmpty
                onClick={() => this.setState({ showReports: false })}
              >
                OK
              </EuiButtonEmpty>
            </EuiModalFooter>
          </EuiModal>
        </EuiOverlayMask>
      );
    }
    if (this.state.showModalUploadClaim) {
      modalUploadClaim = (
        <EuiOverlayMask>
          <EuiModal
            onClose={() => {
              if (this.state.loadingUploadClaimOnDB) {
                return;
              }

              this.setState({
                showModalUploadClaim: false,
                fileUploadClaim: null,
              });
            }}
            initialFocus='[name=reports]'
          >
            <EuiModalHeader>
              <EuiModalHeaderTitle>
                Unggah Klaim Dokumen Asuransi
              </EuiModalHeaderTitle>
            </EuiModalHeader>

            <EuiModalBody>
              <EuiFormRow label='Unggah Dokumen'>
                <EuiFilePicker
                  accept='application/pdf'
                  isInvalid={this.state.errorUploadClaim}
                  onChange={(file) => {
                    this.setState({
                      fileUploadClaim: file[0],
                      errorUploadClaim: false,
                    });
                  }}
                  isLoading={this.state.loadingUploadClaim}
                />
              </EuiFormRow>
            </EuiModalBody>
            <EuiModalFooter>
              <EuiButton
                isLoading={this.state.loadingUploadClaimOnDB}
                onClick={() => {
                  if (this.state.fileUploadClaim?.type !== 'application/pdf') {
                    alert('Berkas yang diunggah hanya boleh berjenis pdf');
                    this.setState({ errorUploadClaim: true });
                    return;
                  }

                  if (
                    !window.confirm(
                      'Apakah Anda yakin ingin menggungah berkas klaim dokumen ini?'
                    )
                  ) {
                    return;
                  }

                  this.setState({ loadingUploadClaimOnDB: true }, () => {
                    blobService
                      .uploadFile(this.state.fileUploadClaim)
                      .then((res) => res)
                      .then((blobID) => {
                        let payload = {};
                        payload.session_id =
                          this.state.selectedSessionInsurance;
                        payload.claim_doc = blobID;
                        return sessionsService.uploadClaimDoc(payload);
                      })
                      .then(() => {
                        swal({
                          title: 'Berhasil unggah klaim dokumen',
                          icon: 'success',
                          type: 'success',
                        }).then((value) => {
                          this.setState(
                            {
                              loadingUploadClaimOnDB: false,
                              showModalUploadClaim: false,
                              loadingData: true,
                            },
                            () => this.page(this.state.page)
                          );
                        });
                      })
                      .catch((err) => {
                        console.log(err);
                        this.setState({ loadingUploadClaimOnDB: false });
                      });
                  });
                }}
                fill
                disabled={
                  this.state.loadingUploadClaimOnDB ||
                  !this.state.fileUploadClaim
                }
              >
                Submit
              </EuiButton>
              <EuiButtonEmpty
                disabled={this.state.loadingUploadClaimOnDB}
                onClick={() =>
                  this.setState({
                    fileUploadClaim: null,
                    showModalUploadClaim: false,
                  })
                }
              >
                Batal
              </EuiButtonEmpty>
            </EuiModalFooter>
          </EuiModal>
        </EuiOverlayMask>
      );
    }

    return (
      <>
        <EuiPage className='class'>
          {modalDescription}
          {modalReports}
          {modalUploadClaim}
          <EuiPageBody
            style={{ flexDirection: 'row' }}
            className={'content-container'}
          >
            <EuiPageContent>
              <EuiPageContentHeader>
                <EuiPageContentHeaderSection>
                  <EuiTitle>
                    <h2>Temu-Janji Konsultasi</h2>
                  </EuiTitle>
                </EuiPageContentHeaderSection>
              </EuiPageContentHeader>
              <EuiPageContentHeader>
                <EuiTabs>{this.renderTabs()}</EuiTabs>
              </EuiPageContentHeader>
              <EuiPageContentHeader>
                {this.renderFilterComponent()}
              </EuiPageContentHeader>
              <EuiPageContentBody>
                {this.state.sessionSlotData &&
                  this.state.sessionSlotData.length < 0 && (
                    <p>Belum ada data</p>
                  )}

                <TablePage
                  placeholder={'Cari'}
                  data={this.state.data}
                  column={this.state.column}
                  page={this.state.page}
                  limit={this.state.limit}
                  total={this.state.total}
                  prev={this.prevPage}
                  next={this.nextPage}
                  toPage={this.page}
                  sort={sorting}
                  change={this.onTableChange}
                  loading={this.state.loadingData}
                  customEmpty={
                    <EuiFlexGroup direction='column'>
                      <EuiFlexItem>
                        <p>
                          <EuiIcon type='clock' size='xxl' />
                        </p>
                      </EuiFlexItem>
                      <EuiFlexItem>
                        <p>
                          {this.state.selectedTabId === 'today'
                            ? 'Tidak ada jadwal hari ini'
                            : 'Tidak ada jadwal'}
                        </p>
                      </EuiFlexItem>
                    </EuiFlexGroup>
                  }
                />
              </EuiPageContentBody>
            </EuiPageContent>
          </EuiPageBody>
        </EuiPage>
      </>
    );
  }
}

export default Consultations;
