import React from 'react';

import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiIcon,
  EuiText,
} from '@elastic/eui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';

const CustomBadge = (props) => {
  const {
    color,
    text,
    iconColor,
    onClick,
    textColor,
    iconType,
    leadingIconColor,
  } = props;
  return (
    <EuiFlexGroup
      onClick={onClick}
      responsive={false}
      gutterSize='xs'
      style={{
        backgroundColor: color === null ? '#D9D9D9' : color,
        borderRadius: '10px',
        alignItems: 'center',
        paddingLeft: '8px',
        paddingRight: '8px',
        marginBottom: '4px',
      }}
    >
        {iconType !== null ? (
          <EuiIcon
            size='xs'
            color={leadingIconColor ?? '#FFFFFF'}
            type={iconType}
          />
        ) : (
          <></>
        )}
      <EuiFlexItem grow={4}>
        <EuiText
          textAlign='left'
          size='xs'
          style={{
            color: textColor === null ? '#000000' : textColor,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-inline-box',
            WebkitLineClamp: 1,
            WebkitBoxOrient: 'vertical',
          }}
        >
          {text}
        </EuiText>
      </EuiFlexItem>
      {iconColor !== null ? (
        <EuiFlexItem grow={1}>
          <FontAwesomeIcon icon={faCircle} color={iconColor} />
        </EuiFlexItem>
      ) : (
        <div />
      )}
    </EuiFlexGroup>
  );
};

export default CustomBadge;
