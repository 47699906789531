import React, { Component } from 'react';

import { EuiBadge, EuiButton, EuiDatePicker, EuiDatePickerRange, EuiFlexGroup, EuiFlexItem, EuiLink, EuiModal, EuiModalBody, EuiModalFooter, EuiModalHeader, EuiModalHeaderTitle, EuiOverlayMask, EuiPage, EuiPageBody, EuiPageContent, EuiTitle, EuiSelect, EuiComboBox, EuiText, EuiPageHeaderSection } from '@elastic/eui';
import PaymentService from '../services/Payments';
import moment from 'moment';
import TablePage from '../components/TablePage';
import * as XLSX from 'xlsx';

const paymentService = new PaymentService();
const bankCodeNames = require('../assets/bankCodeNames.json');
const paymentCodeNames = {
  CC: 'Kartu Kredit',
  DC: 'Kartu Debit',
  VA: 'Virtual Account',
};
const paymentStatuses = {
  0: 'UNPAID',
  1: 'PAID',
  2: 'UNDERPAID',
  3: 'OVERPAID',
  4: 'CANCELED',
  5: 'REPLACED',
};
const statusSessionRender = (status) => {
  switch (status) {
    case 0:
      return 'NEW';
    case 1:
      return 'ACTIVE';
    case 2:
      return 'REJECTED';
    case 3:
      return 'DISABLED';
    case 4:
      return 'ENDED';
    case 5:
      return 'CANCELED';
    case 7:
      return 'REFUND';
    default:
      return 'UNKNOWN';
  }
};

const prescriptionStatus = (status) => {
  switch (status) {
    case 0:
      return 'CREATED'  
    case 1:
      return 'INVOICE'
    case 2:
      return 'PAID'
    case 3:
      return 'PROCESSED'
    case 4:
      return 'READY FOR PICKUP'
    case 8:
      return 'CANCELLED'
    case 9:
      return 'FAILED'
    case 10:
      return 'DONE'
    default:
      return 'UNKNOWN';
  }
}

const diagnosticStatus = (status) => {
  switch (status) {
    case 0:
      return 'CREATED'  
    case 1:
      return 'INVOICE'
    case 2:
      return 'PAID'
    case 3:
      return 'PROCESSED'
    case 10:
      return 'DONE'
    default:
      return 'UNKNOWN';
  }
}

// Change currency format
const f = Intl.NumberFormat('id-ID', {
  style: 'currency',
  currency: 'IDR',
});
class Payments extends Component {
  state = {
    page: 1,
    limit: 10,
    order: 'ASC',
    search: '',
    loading: false,
    orderBy: 'name',
    data: [],
    dataExcel: [],
    openPatientDetail: false,
    patientDetail: {},
    openStaffDetail: false,
    staffDetail: {},
    openExcelDownloadOption: false,
    startDate: moment().add(-1, 'M'),
    endDate: moment(),
    loadingDownloadExcel: false,
    column: [
      {
        field: 'purchase_date',
        name: 'Tanggal Transaksi',
      },
      {
        field: 'metadata.patient',
        name: 'Nama Pasien',
        render: (data) => (
          <EuiLink 
            onClick={() => this.showDetailPatient(data)}
          >
              {data?.name}
          </EuiLink>
        )
      },
      {
        field: 'schedule_start_session',
        name: 'Tanggal Sesi',
      },
      {
        field: 'metadata.staff',
        name: 'Nama Staff',
        render: (data) => (
          <EuiLink onClick={() => this.showDetailStaff(data)}>
            {data?.name}
            </EuiLink>
        )
      },
      {
        field: 'payment_type',
        name: 'Jenis Pembayaran',
      },
      {
        field: 'payment_method',
        name: 'Metode Pembayaran',
        align: 'left',
        truncateText: true,
      },
      {
        field: 'partner_tx_id',
        name: 'Kode Transaksi',
        textOnly: false,
      },
      {
        field: 'amount.units',
        name: 'Jumlah (IDR)',
        align: 'right',
        render: (data) => (
          <EuiText>
            {f.format(data || 0)}
          </EuiText>
        )
      },
    ],
    filterPayments: [
      {
        value: 'all',
        text: 'Tampilkan semua',
      },
      {
        value: 'payment-method',
        text: 'Metode Pembayaran',
      },
      {
        value: 'payment-type',
        text: 'Jenis Pembayaran',
      }
    ],
    filterPaymentTypes: [
      {
        value: 'all',
        text: 'Tampilkan semua',
      },
      {
        value: 'SESSION',
        text: 'Telekonsultasi',
      },
      {
        value: 'PRESCRIPTION',
        text: 'Resep Obat',
      },
      {
        value: 'DIAGNOSTIC',
        text: 'Uji Lab',
      },
    ],
    filterPaymentMethods: [
      {
        value: 'OY',
        label: 'OY',
      },
      {
        value: 'DOKU',
        label: 'DOKU',
      },
      {
        value: 'virtual-clinic',
        label: 'Virtual Klinik',
      },
      {
        value: 'ADMEDIKA',
        label: 'Admedika',
      },
    ],
    filterPayment: [],
    filterType: 'all',
    filterBy: '',
    filterValue: '',
  };

  showDetailPatient = (data) => {
    this.setState({ 
      openPatientDetail: true, 
      patientDetail: data 
    });
  }

  showDetailStaff = (data) => {
    this.setState({ 
      openStaffDetail: true, 
      staffDetail: data 
    });
  }

  page = (page) => {
    page = page || 1;
    this.setState({ loading: true });
    let filterPayment = [];
    for (let i in this.state.filterPayment) {
      filterPayment.push(this.state.filterPayment[i]?.value);
    }
    let payload = {
      page: page,
      limit: this.state.limit,
      search: this.state.search,
      order: "DESC",
      orderBy: "p.payment_time",
      startDate: this.state.startDate.format("YYYY-MM-DD"),
      endDate: this.state.endDate.format("YYYY-MM-DD"),
      filterProductType: this.state.filterType === 'all' ? '' : this.state.filterType,
      filterPaymentMethod: filterPayment.toString(),
    };
    paymentService
      .getPayments(payload)
      .then((result) => {
        let detailData = [];

        if (result.data) {
          console.log(result.data);
          for (var i in result.data) {
            let item = result.data[i];
            if (item?.metadata && item?.metadata.length > 0 ) {
                  item.metadata = JSON.parse(item.metadata);
                  if (item?.metadata?.patient != null && item?.metadata?.staff != null && item?.metadata?.clinic != null) {
                      item.description =item?.metadata?.clinic?.name + ' / ' + item?.metadata?.staff?.name;
                      item.metadata.patient.dob = item?.metadata?.patient?.dob.substr(0,10);    
                    }
            } 
            item.purchase_date = item.payment_timestamp.substr(0, 10);
            item.payment_status = paymentStatuses[item.payment_status];
            item.payment_type = item?.metadata?.session?.id !== undefined ? "Telekonsultasi" : item?.metadata?.prescription?.id !== undefined ? "Resep Obat" : "Uji Lab";
            item.schedule_start_session = this.getSessionTime(item);
          }
        }
        console.log(result.data)
        this.setState(
          {
            page: result.page,
            limit: result.limit,
            total: result.total,
            order: result.order,
            orderBy: result.orderBy,
            data: result.data || [],
            loading: false,
          },
          () => {
            this.forceUpdate();
          }
        );
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          loading: false,
          data: [],
        });
      });
  };
  
  // Get item time
  getSessionTime = (item) => {
    if (item?.metadata?.session?.schedule) {
      return  item?.metadata?.session?.schedule.substr(0, 10)
    } else if (item?.metadata?.session?.scheduleStartUtc) {
    return  item?.metadata?.session?.scheduleStartUtc.substr(0, 10)
   } else if (item?.metadata?.prescription?.created_at) {
    return item?.metadata?.prescription?.created_at.split(" ")[0]
   } else if (item?.metadata?.diagnostic?.created_at) {
      return moment.unix(item?.metadata?.diagnostic?.created_at).format("YYYY-MM-DD")
   } else {
    return "-"
   } 
  }

  nextPage = () => {
    this.page(this.state.page + 1);
  };

  prevPage = () => {
    this.page(this.state.page - 1);
  };
  componentDidMount = () => {
    this.page(1);
  };

  downloadExcelFile = async () => {
    this.setState({ loadingDownloadExcel: true });
    let paymentData = [];

    let filterPayment = [];
    for (let i in this.state.filterPayment) {
      filterPayment.push(this.state.filterPayment[i]?.value);
    }
    let payload = {
      page: 1,
      limit: 999999,
      search: this.state.search,
      order: "DESC",
      orderBy: "p.payment_time",
      startDate: this.state.startDate.format("YYYY-MM-DD"),
      endDate: this.state.endDate.format("YYYY-MM-DD"),
      filterProductType: this.state.filterType === 'all' ? '' : this.state.filterType,
      filterPaymentMethod: filterPayment.toString(),
    };

    await paymentService
      .getPayments(payload)
      .then((result) => {
        if (result.data) {
          console.log(result.data);
          for (var i in result.data) {
            let item = result.data[i];
            if (item?.metadata && item?.metadata.length > 0 ) {
                  item.metadata = JSON.parse(item.metadata);
                  if (item?.metadata?.patient != null && item?.metadata?.staff != null && item?.metadata?.clinic != null) {
                      item.description =item?.metadata?.clinic?.name + ' / ' + item?.metadata?.staff?.name;
                      item.metadata.patient.dob = item?.metadata?.patient?.dob.substr(0,10);    
                    }
            } 
            item.purchase_date = item.payment_timestamp.substr(0, 10);
            item.payment_status = paymentStatuses[item.payment_status];
            item.payment_type = item?.metadata?.session?.id !== undefined ? "Telekonsultasi" : item?.metadata?.prescription?.id !== undefined ? "Resep Obat" : "Uji Lab";
            item.schedule_start_session = this.getSessionTime(item);
          }
        }
        console.log(result.data)
          this.setState(
            {
              dataExcel: result.data || [],
              loadingDownloadExcel: false,
              openExcelDownloadOption: false,
            },
            () => {
              this.forceUpdate();
            }
          );
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          loadingDownloadExcel: false,
          dataExcel: [],
          openExcelDownloadOption: false
        });
      });
    let countNo = 0;
    this.state.dataExcel.map((val, idx) => {  
      countNo++
      if (this.state.filterType === 'SESSION'){
        paymentData.push({
          no: countNo,
          schedule_start_session: val?.schedule_start_session,
          status: statusSessionRender(val?.metadata?.session?.status),
          patient_name: val?.metadata?.patient?.name,
          staff_name: val?.metadata?.staff?.name,
          organization_name: val?.metadata?.staff?.organization_name,
          specialization: val?.metadata?.staff?.specialization,
          staff_type: val?.metadata?.staff?.type,
          price: val?.amount?.units
        });
      } else if (this.state.filterType === 'PRESCRIPTION') {
        let item = []
        for (let x in val?.metadata?.prescription?.items) {
          let medicineName = val?.metadata?.prescription?.items[x]?.medicine_name;
          let price = val?.metadata?.prescription?.items[x]?.price?.units;
          let amount = val?.metadata?.prescription?.items[x]?.numero;
          item.push(
            `{nama_obat: ${medicineName}, harga: ${price}, jumlah: ${amount}}`
          )
        }

        paymentData.push({
          no: countNo,
          id: val?.metadata?.prescription?.id,
          patient_name: val?.metadata.patient?.name,
          staff_name: val?.metadata?.staff?.name,
          pharmacy_name: val?.metadata?.prescription?.pharmacy_name,
          pharmacy_address: val?.metadata?.prescription?.pharmacy_address,
          price: val?.amount?.units,
          status: prescriptionStatus(val?.metadata?.prescription?.status),
          pharmacy_prescription_id: val?.metadata?.prescription?.pharmacy_prescription_id,
          item_and_price: item.toString(),
          partner_tx_id: val?.partner_tx_id,
          date: val?.purchase_date,
        });
      } else if (this.state.filterType === 'DIAGNOSTIC') {
        let item = []
        for (let x in val?.metadata?.diagnostic?.services) {
          item.push(
            val?.metadata?.diagnostic?.services[x]?.name
          )
        }
        paymentData.push({
          no: countNo,
          date: this.getSessionTime(val),
          code: val?.id,
          patient_name: val?.metadata.patient?.name,
          staff_name: val?.metadata?.staff?.name,
          lab_name: val?.metadata?.diagnostic?.lab_name,
          lab_address: val?.metadata?.diagnostic?.lab_address,
          status: diagnosticStatus(val?.metadata?.diagnostic?.status),
          item: item.toString(),
          price: val?.amount?.units,
          partner_tx_id: val?.partner_tx_id,
        });
      } else {
        paymentData.push({
          no: countNo,
          schedule_start_session: val?.schedule_start_session,
          status: statusSessionRender(val?.metadata?.session?.status),
          patient_name: val?.metadata?.patient?.name,
          staff_name: val?.metadata?.staff?.name,
          organization_name: val?.metadata?.staff?.organization_name,
          specialization: val?.metadata?.staff?.specialization,
          staff_type: val?.metadata?.staff?.type,
          price: val?.amount?.units
        });
      }

    });
    let workbook = XLSX.utils.book_new();
    let worksheet = XLSX.utils.json_to_sheet(paymentData);
    XLSX.utils.book_append_sheet(workbook, worksheet, "sheet1", true);
    XLSX.writeFile(workbook, `payment-report-${this.state.filterType.toLowerCase()}-${this.state.startDate.format("DD MMM YYYY")}-${this.state.endDate.format("DD MMM YYYY")}.xls`);
  }

  render() {
    let modalDetailPatient;
    let modalDetailStaff;
    let modalExcelDownload;

    if (this.state.openPatientDetail) {
      modalDetailPatient = (
      <EuiOverlayMask>
        <EuiModal onClose={() => this.setState({ openPatientDetail: false })}>
          <EuiModalHeader>
            <EuiModalHeaderTitle>Detail Pasien</EuiModalHeaderTitle>
          </EuiModalHeader>
          <EuiModalBody>
            <EuiFlexGroup>
              <EuiFlexItem component='span' grow={2}>Nama Pasien</EuiFlexItem>
              <EuiFlexItem component='span' grow={1}>:</EuiFlexItem>
              <EuiFlexItem component='span' grow={2}>{this.state.patientDetail.name !== undefined ? this.state.patientDetail.name : "Unknown"}</EuiFlexItem>
            </EuiFlexGroup>
            <EuiFlexGroup>
              <EuiFlexItem component='span' grow={2}>MRN</EuiFlexItem>
              <EuiFlexItem component='span' grow={1}>:</EuiFlexItem>
              <EuiFlexItem component='span' grow={2}>{this.state.patientDetail.externalId !== undefined ? this.state.patientDetail.externalId : "Unknown"}</EuiFlexItem>
            </EuiFlexGroup>
            <EuiFlexGroup>
              <EuiFlexItem component='span' grow={2}>Tanggal Lahir</EuiFlexItem>
              <EuiFlexItem component='span' grow={1}>:</EuiFlexItem>
              <EuiFlexItem component='span' grow={2}>{this.state.patientDetail.dob !== undefined ? this.state.patientDetail.dob : "Unknown"}</EuiFlexItem>
            </EuiFlexGroup>
            <EuiFlexGroup>
              <EuiFlexItem component='span' grow={2}>Nomor HP</EuiFlexItem>
              <EuiFlexItem component='span' grow={1}>:</EuiFlexItem>
              <EuiFlexItem component='span' grow={2}>{this.state.patientDetail.phoneNumber !== undefined ? this.state.patientDetail.phoneNumber : "Unknown"}</EuiFlexItem>
            </EuiFlexGroup>
          </EuiModalBody>
          <EuiModalFooter>
            <EuiButton onClick={() => { this.props.history.push("/patient/detail/" + this.state.patientDetail?.id) }} color='primary' fill>Buka Halaman Pasien</EuiButton>
            <EuiButton onClick={() => this.setState({ openPatientDetail: false })} >Close</EuiButton>
          </EuiModalFooter>
        </EuiModal>
      </EuiOverlayMask>
      )
    }

    if (this.state.openStaffDetail) {
      modalDetailStaff = (
        <EuiOverlayMask>
        <EuiModal onClose={() => this.setState({ openStaffDetail: false })}>
          <EuiModalHeader>
            <EuiModalHeaderTitle>Detail Staff</EuiModalHeaderTitle>
          </EuiModalHeader>
          <EuiModalBody>
            <EuiFlexGroup>
              <EuiFlexItem component='span' grow={2}>Nama Staff</EuiFlexItem>
              <EuiFlexItem component='span' grow={1}>:</EuiFlexItem>
              <EuiFlexItem component='span' grow={2}>{this.state.staffDetail.name !== undefined ? this.state.staffDetail.name : "Unknown"}</EuiFlexItem>
            </EuiFlexGroup>
            <EuiFlexGroup>
              <EuiFlexItem component='span' grow={2}>Spesialis</EuiFlexItem>
              <EuiFlexItem component='span' grow={1}>:</EuiFlexItem>
              <EuiFlexItem component='span' grow={2}>{this.state.staffDetail.specialization !== undefined ? this.state.staffDetail.specialization : "Unknown"}</EuiFlexItem>
            </EuiFlexGroup>
            <EuiFlexGroup>
              <EuiFlexItem component='span' grow={2}>SIP</EuiFlexItem>
              <EuiFlexItem component='span' grow={1}>:</EuiFlexItem>
              <EuiFlexItem component='span' grow={2}>{this.state.staffDetail.sip !== undefined ? this.state.staffDetail.sip : "Unknown"}</EuiFlexItem>
            </EuiFlexGroup>
            <EuiFlexGroup>
              <EuiFlexItem component='span' grow={2}>Nomor HP</EuiFlexItem>
              <EuiFlexItem component='span' grow={1}>:</EuiFlexItem>
              <EuiFlexItem component='span' grow={2}>{this.state.staffDetail.phone_number !== undefined ? this.state.staffDetail.phone_number : "Unknown"}</EuiFlexItem>
            </EuiFlexGroup>
            <EuiFlexGroup>
              <EuiFlexItem component='span' grow={2}>Nama Organisasi</EuiFlexItem>
              <EuiFlexItem component='span' grow={1}>:</EuiFlexItem>
              <EuiFlexItem component='span' grow={2}>{this.state.staffDetail.organization_name !== undefined ? this.state.staffDetail.organization_name : "Unknown"}</EuiFlexItem>
            </EuiFlexGroup>
            <EuiFlexGroup>
              <EuiFlexItem component='span' grow={2}>Jenis Staff</EuiFlexItem>
              <EuiFlexItem component='span' grow={1}>:</EuiFlexItem>
              <EuiFlexItem component='span' grow={2}>{this.state.staffDetail.type !== undefined ? this.state.staffDetail.type : "Unknown"}</EuiFlexItem>
            </EuiFlexGroup>
          </EuiModalBody>
          <EuiModalFooter>
            <EuiButton onClick={() => { this.props.history.push("/staff/detail/" + this.state.staffDetail?.id) }} color='primary' fill>Buka Halaman Staff</EuiButton>
            <EuiButton onClick={() => this.setState({ openStaffDetail: false })} >Close</EuiButton>
          </EuiModalFooter>
        </EuiModal>
      </EuiOverlayMask>
      )
    }

    if (this.state.openExcelDownloadOption) {
      modalExcelDownload = (
        <EuiOverlayMask>
        <EuiModal onClose={() => this.setState({ openExcelDownloadOption: false })} style={{ width: 450, height: 600 }}>
          <EuiModalHeader>
            <EuiModalHeaderTitle>Filter</EuiModalHeaderTitle>
          </EuiModalHeader>
          <EuiModalBody>
            <EuiFlexGroup>
              <EuiFlexItem component='span' grow={false}>
                Tanggal Periode
              </EuiFlexItem>
            </EuiFlexGroup>
            <EuiFlexGroup>
              <EuiFlexItem component='span' grow={false}>
                <EuiDatePickerRange
                  startDateControl={
                    <EuiDatePicker
                      selected={this.state.startDate}
                      startDate={this.state.startDate}
                      onChange={ (date) => date && this.setState({ startDate: date })}
                      endDate={this.state.endDate}
                      aria-label='start-date-excel'
                    />
                  }
                  endDateControl={
                    <EuiDatePicker
                      selected={this.state.endDate}
                      startDate={this.state.startDate}
                      onChange={ (date) => date && this.setState({ endDate: date })}
                      endDate={this.state.endDate}
                      aria-label='end-date-excel'
                    />
                  }
                />
              </EuiFlexItem>
            </EuiFlexGroup>
            <EuiFlexGroup>
              <EuiFlexItem component='span' grow={false}>
                Jenis Pembayaran
              </EuiFlexItem>
            </EuiFlexGroup>
            <EuiFlexGroup>
              <EuiFlexItem grow>
                <EuiSelect
                  options={this.state.filterPaymentTypes}
                  value={this.state.filterType}
                  name='filterType'
                  onChange={(e) =>
                    this.setState({ filterType: e.target.value })
                  }
                  aria-label='filterType'
                />
              </EuiFlexItem>
            </EuiFlexGroup>
            <EuiFlexGroup>
              <EuiFlexItem component='span' grow={false}>
                Metode Pembayaran
              </EuiFlexItem>
            </EuiFlexGroup>
            <EuiFlexGroup>
              <EuiFlexItem grow>
                <EuiComboBox
                  aria-label='payment-type-label'
                  placeholder='Pilih jenis pembayaran'
                  options={this.state.filterPaymentMethods}
                  selectedOptions={this.state.filterPayment}
                  onChange={(v) =>
                    this.setState({ filterPayment: v })
                  }
                  isClearable={true}
                  autoFocus
                />
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiModalBody>
          <EuiModalFooter>
            <EuiButton onClick={() => this.setState({ openExcelDownloadOption: false })} >Tutup</EuiButton>
            <EuiButton  
              onClick={() => { 
                this.page(1);
                this.setState({ openExcelDownloadOption: false });
              }} 
              fill > 
              Cari 
            </EuiButton>
          </EuiModalFooter>
        </EuiModal>
      </EuiOverlayMask>
      )
    }
    
    return (
      <>
        <EuiPage className="class">
          {modalDetailPatient}
          {modalDetailStaff}
          {modalExcelDownload}
          <EuiPageBody
            style={{ flexDirection: 'row' }}
            className={'content-container'}
          >
            <div style={{ width: '100%' }}>
              
              <div>
                <TablePage
                  title={`Pembayaran`}
                  placeholder={'Nomor Tagihan'}
                  data={this.state.data}
                  column={this.state.column}
                  location={this.props.location}
                  disablePagination={this.state.loading}
                  page={this.state.page}
                  limit={this.state.limit}
                  total={this.state.total}
                  prev={this.prevPage}
                  next={this.nextPage}
                  toPage={this.page}
                  loading={this.state.loading}
                  subHeader={
                    <> 
                      <EuiPageHeaderSection>
                        <EuiFlexGroup >
                          <EuiFlexItem grow={false}>
                            <EuiText size='s'>
                              Periode : {this.state.startDate?.format("DD-MM-YYYY")} s/d {this.state.endDate?.format("DD-MM-YYYY")}
                            </EuiText>
                          </EuiFlexItem>
                        </EuiFlexGroup>
                      </EuiPageHeaderSection>
                      <EuiPageHeaderSection>
                        <EuiFlexGroup>
                          <EuiFlexItem grow={false}>
                            <EuiText size='s'>
                              Total Transaksi : {this.state?.total || '0'}
                            </EuiText>
                          </EuiFlexItem>
                        </EuiFlexGroup>
                      </EuiPageHeaderSection>
                    </>
                  }
                  searchComp={
                    <>
                      <EuiFlexGroup>
                        <EuiFlexItem grow={false}>
                          <EuiButton
                            onClick={() => { this.setState({ openExcelDownloadOption: true }) }}
                            fill
                          >
                            Filter
                          </EuiButton>
                        </EuiFlexItem>
                        <EuiFlexItem grow={false}>
                          <EuiButton
                            isLoading={this.state.loadingDownloadExcel}
                            onClick={() => { this.downloadExcelFile() }}
                          >
                            Unduh Excel
                          </EuiButton>
                        </EuiFlexItem>
                      </EuiFlexGroup>
                    </>
                  }
                />
              </div>
            </div> 
          </EuiPageBody>
        </EuiPage>
      </>
    );
  }
}

export default Payments;
