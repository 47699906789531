import Config from '../Config';
import axios from 'axios';
import Error from './Error';
import Misc from './Misc';

const ErrorService = new Error();
const MiscService = new Misc();

class Laboratory {
  getDiagnosticRequests = (payload) => {
    return new Promise((resolve, reject) => {
      payload = payload || {};
      payload.page = payload.page || 1;
      payload.limit = payload.limit || 10;
      payload.order = payload.Order || 'DESC';
      payload.order_by = payload.order_by || 'created_at';
      payload.search = payload.search || '';
      let url = `${Config.apiHost}/api/v1/diagnostic-requests?page=${payload.page}&limit=${payload.limit}&order=${payload.order}&order_by=${payload.order_by}&search=${payload.search}`;
      if (payload.key !== null && payload.key !== undefined) {
        url += `&key=${payload.key}&value=${payload.value}`;
      }
      axios
        .get(url, { headers: MiscService.generateHeaders() })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
          ErrorService.handle(err);
        });
    });
  };

  getDiagnosticReceiptBySession = (sessionId) => {
    return new Promise((resolve, reject) => {
      let url = `${Config.apiHost}/api/diagnostic/receipt/${sessionId}`;
      axios
        .get(url, {
          headers: MiscService.generateHeaders(),
          responseType: 'arraybuffer',
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
          ErrorService.handle(err);
        });
    });
  };

  getDiagnosticReferralDoc = (sessionId, lang) => {
    lang = lang || 'id';
    return new Promise((resolve, reject) => {
      let url = `${Config.apiHost}/api/session/lab-referral-letter/${sessionId}/${lang}`;
      axios
        .get(url, {
          headers: MiscService.generateHeaders(),
          responseType: 'arraybuffer',
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
          ErrorService.handle(err);
        });
    });
  };

  getDiagnosticService = (payload) => {
    return new Promise((resolve, reject) => {
      payload = payload || {};
      payload.page = payload.page || 1;
      payload.limit = payload.limit || 10;
      payload.order = payload.Order || 'ASC';
      payload.search = payload.Search || '';
      let url = `${Config.apiHost}/api/diagnostic-services?search=${payload.search}`;
      axios
        .get(url, { headers: MiscService.generateHeaders() })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  getDiagnosticRequestBySession = (sessionId) => {
    return new Promise((resolve, reject) => {
      let url = `${Config.apiHost}/api/diagnostic-request/${sessionId}`;
      axios
        .get(url, { headers: MiscService.generateHeaders() })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  createDiagnosticRequest = (payload) => {
    return new Promise((resolve, reject) => {
      let url = `${Config.apiHost}/api/diagnostic-request`;
      axios
        .post(url, payload, { headers: MiscService.generateHeaders() })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  // Helper

  renderDate = (time) => {
    var d = new Date(time * 1000);
    var fullDate = d.toDateString();
    return fullDate;
  };

  renderStatus = (status) => {
    switch (status) {
      case 0:
        return 'Created';
      case 1:
        return 'Invoice';
      case 2:
        return 'PAID';
      case 3:
        return 'PROCEEDED';
      case 4:
        return 'RESERVED_4';
      case 5:
        return 'RESERVED_5';
      case 6:
        return 'RESERVED_6';
      case 7:
        return 'RESERVED_7';
      case 8:
        return 'RESERVED_8';
      case 9:
        return 'RESERVED_9';
      case 10:
        return 'DONE';
      default:
        return 'UNKNOWN';
    }
  };
}

export default Laboratory;
